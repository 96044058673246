<template>
  <div id="reset" class="h-screen">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <transition name="fade">
      <Notification />
    </transition>


    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8" v-if="beforeSent">
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-14 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="flex-shrink-0 flex">
          <router-link to="/">
            <ChevronLeftIcon class="h-auto w-8 text-gray-300 hover:text-gray-400 transition ease-in-out duration-300" aria-hidden="true" />
          </router-link>
        </div>
          <div class="sm:mx-auto sm:w-full sm:max-w-sm sm:pb-10 flex flex-col justify-center items-center">
            <a href="https://complaia.com/"><img class="h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia.com - complaint system for buisness" /></a>
            <h2 class="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900 select-none">Forgot your password?</h2>
            <p class="mt-2 text-center text-sm text-gray-400 select-none">
              Please fill in the email that you used to register. You will be sent an email with instructions on how to reset your password.
            </p>
          </div>
          <form class="space-y-6 sm:space-y-12" action="#" method="POST">

            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input type="email" name="email" id="email" v-model="email" :class="{'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm transition-all': !error.email, 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm': error.email }" placeholder="you@example.com" aria-invalid="true" aria-describedby="email-error" />
                <div v-if="error.email" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              </div>
              <p v-if="error.email" class="mt-2 text-sm text-red-600" id="email-error">{{ error.emailText }}</p>
            </div>

            <div>
              <button @click.prevent="checkEmail(this.email)" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300">Send email</button>
            </div>
          </form>

          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="bg-white px-2 text-gray-500">
                  <p class="text-center text-sm text-gray-600 select-none">
                  Remember your password?
                  {{ ' ' }}
                  <router-link to="/" class="font-medium text-blue-500 hover:text-blue-400">Sign in</router-link>
                </p></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Transition>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8" v-if="successfullySent">
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-14 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm sm:pb-10">
            <img class="mx-auto h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Your Company" />
            <h2 class="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900 select-none">Email Sent!</h2>
            <p class="mt-2 text-center text-sm text-gray-600 select-none">
              An email has been sent to your email address with instructions on how to reset your password. If you don’t receive it within a few minutes, please check that you used the e-mail address for your Complaia account and try again or contact us for help.
            </p>
          </div>
          <div>
            <router-link to="/" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300">Sign In</router-link>
          </div>
        </div>
      </div>
    </div>
    </Transition>

    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8" v-if="Object.keys(this.$route.query).length > 0">
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-14 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm sm:pb-10">
            <img class="mx-auto h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Your Company" />
            <h2 class="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900 select-none">Reset your password</h2>
            <p class="mt-2 text-center text-sm text-gray-400 select-none">
              for {{ this.email }}
            </p>
          </div>
          <form class="space-y-6" action="#" method="POST">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700 select-none">Email address</label>
              <div class="mt-1">
                <input id="email" name="email" type="email" autocomplete="email" required="" v-model="email" disabled class="cursor-not-allowed border-gray-200 bg-gray-50 text-gray-500appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm transition-all" />
              </div>
            </div>
            <div>
              <label for="password" class="block text-sm font-medium text-gray-700 select-none">Password</label>
              <div class="mt-1">
                <input id="password" name="password" type="password" v-model="newPassword" required="" :class="{'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm transition-all': true, 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm': error.passwordLength, 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm': error.passwordMatch }" />
              </div>
               <p v-if="this.newPassword.length < 6" class="mt-2 text-sm text-gray-500 select-none" id="password-description">Password must have a minimum of 6 characters.</p>
            </div>

            <Transition>
              <div v-if="newPassword.length >= 6">
                <label for="password" class="block text-sm font-medium text-gray-700 select-none">Repeat Password</label>
                <div class="mt-1">
                  <input id="password" name="password" type="password" v-model="newPasswordRepeat" required="" :class="{'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm transition-all': true, 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm': error.passwordLength, 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm': error.passwordMatch }" />
                </div>
              </div>
            </Transition>

              <div class="rounded-md bg-red-50 p-4" v-if="error.passwordLength || error.passwordMatch">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">Something went wrong during reset action.</h3>
                    <div class="mt-2 text-sm text-red-700">
                      <ul role="list" class="list-disc space-y-1 pl-5">
                        <li v-if="error.passwordLength">Your password must be at least 6 characters</li>
                        <li v-if="error.passwordMatch">Your password must match to each other</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            <div>
              <button @click.prevent="changePassword()" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300">Change password</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ExclamationCircleIcon, XCircleIcon, ChevronLeftIcon } from '@heroicons/vue/solid'
import Loader from '../components/Loader.vue';
import Notification from '../components/Notification.vue';
import axios from 'axios';
export default {
  name: "Reset",
  components: {
    Loader, Notification, ExclamationCircleIcon, XCircleIcon, ChevronLeftIcon
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      beforeSent: true,
      successfullySent: false,
      email: '',
      newPassword: '',
      newPasswordRepeat: '',
      passwordNote: false,
      error: {
        email: false,
        emailText: '',
        passwordMatch: false,
        passwordLength: false,
      },
      pages: [
        { name: 'Projects', href: '#', current: false },
      ]
    }
  },
  methods: {
    async recoveryPassword(email) {
      try {
        this.$store.commit('setGlobalLoader',{
          show: true,
          head: "We are sending you an email with a link.",
          subheader: `Do not close this tab.`,
          });
        if(this.error.email === false) {
          await this.$store.dispatch("reset", {
            email: email,
          });
        }
        if(this.$store.state.authData.status !== "ERROR") {
          this.beforeSent = false;
          this.successfullySent = true
          setTimeout(() => {
           this.$store.commit("resetGlobalLoader");
          }, 500);
        } else {
          this.beforeSent = true;
          this.successfullySent = false;
          this.$store.commit("resetGlobalLoader");

          if(this.$store.state.authData.log.code === "auth/email-not-found") {
            this.error.email = true
            this.error.emailText = 'Sorry, your account was not found.'
          }
          else
          {
            setTimeout(() => {
            this.$store.commit("resetGlobalLoader");
            this.$store.commit('setNotification',{
              show: true,
              head: "You are unable to reset the password for this account!",
              subheader: ``,
              success: false
            });
            }, 250);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    checkEmail(email) {
      this.email = email
      // Check if email is OK
      if( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email))
      {
        this.error.email = false
        this.recoveryPassword(this.email)
      }
      else
      {
        this.error.email = true
        this.error.emailText = 'Your email is incorrect. (Example: joedoe@gmail.com)'
        return 0;
      }
      },
    async getEmail() {
      try
      {
        const result = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/initialize/validateParams', {
          params: {
            oobCode: this.$route.query.oobCode,
            apiKey: this.$route.query.apiKey,
          },
        })
        this.email = result.data.email;
        if(result.data.error === undefined)
        {
          console.log('ok');
        }
        else if(result.data.error.status === 400)
        {
          this.$router.push('/')
        }

      }
      catch (error)
      {
        console.log(error.message);
      }
    },
    async changePassword() {
      try
      {
        if(this.newPassword.length >= 6 && this.newPasswordRepeat.length >= 6 ) {
          if(this.newPassword === this.newPasswordRepeat) {
            this.$store.commit('setGlobalLoader',{
              show: true,
              head: "Changing your password..",
              subheader: `Don't close this tab.`,
              success: false
            });
            this.successfullyChanged = false
            const result = await axios.post(`https://europe-central2-complaia.cloudfunctions.net/api/initialize/changePassword`, {
              'oobCode': `${this.$route.query.oobCode}`,
              'apiKey': `${this.$route.query.apiKey}`,
              'newPassword': `${this.newPassword}`,
            },
          );

          if(result.data.status === 'Error')
          {
            this.$store.commit("resetGlobalLoader");
            this.$store.commit('setNotification',{
              show: true,
              head: "Your password hasn't been changed!",
              subheader: `Try again with different password.`,
              success: false
            });
          }
          else
          {
            await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
              attendantEmail: 'no-reply@complaia.com',
                to: this.email,
                messageType: "passwordChanged",
                payload: { fullname: result.data.user.name + ' ' + result.data.user.surname }
              })
              this.$router.push('/success');
              this.$store.commit("resetGlobalLoader");
            }
          } else {
            this.error.passwordMatch = true
          } // passw match

        } else {
          this.error.passwordLength = true
        } //passw length

      }
      catch (error)
      {
        console.log(error.message);
      }
    }
  },
  created() {

  },
  async mounted() {
    if(Object.keys(this.$route.query).length > 0) {
      this.successfullySent = false;
      this.beforeSent = false;
      await this.getEmail()
    }
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
