<template>
  <div id="reset" class="h-screen">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>

    <Transition>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-14 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm sm:pb-10">
            <img class="mx-auto h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Your Company" />
            <h2 class="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900 select-none">Password Reset Successful!</h2>
          </div>

          <div>
            <router-link to="/" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300">Sign In</router-link>
          </div>

        </div>
      </div>
    </div>
    </Transition>


  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
export default {
  name: "Reset",
  components: {
    Loader
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      pwReset: false,
      beforeSent: true,
      successfullyChanged: false,
      successfullySent: false,
      error: false,
      email: '',
      newPassword: '',
      emailError: {
        error: false,
      }
    }
  },
  methods: {
  async mounted() {
      await this.getEmail()
    }
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity .5s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
