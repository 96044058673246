<template>
  <div id="login" class="h-screen">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <div class="min-h-full flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <transition name="fade">
            <div class="rounded-md bg-red-50 p-4 transition-all mb-6" v-show="error">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    Something went wrong during log in action.
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                      <li>
                        Check your e-mail or password.
                      </li>
                      <li>
                        Check your connection with Internet.  
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div>
            <a href="https://complaia.com/"><img class="h-12 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia.com - complaint system for buisness" /></a>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
              Log in into your account
            </h2>
            <p class="mt-2 text-sm text-gray-600">
              Or
              {{ ' ' }}
              <router-link to="/selectplan" class="font-medium text-blue-500 hover:text-blue-400 transition-all">create new account now.</router-link>
            </p>
          </div>

          <div class="mt-8">
            <div>
              <div class="mt-6 relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-white text-gray-500">
                    Enter your email and password below.
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <form action="#" method="POST" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input id="email" name="email" placeholder="E-mail" type="email" v-model="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm transition-all" />
                  </div>
                </div>

                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div class="mt-1">
                    <input id="password" placeholder="Password" name="password" v-model="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 text-sm transition-all" />
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div class="text-sm">
                    <router-link to="/resetpw" class="font-medium text-blue-500 hover:text-blue-400">Forgot your password?</router-link>
                  </div>
                </div>
                <div>
                  <button @click.prevent="logInUser" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Fapp.png?alt=media&token=55f6dae6-9c9d-4d33-ad81-25ae470b8763" alt="Customer support system. Complaint system" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
export default {
  name: "Login",
  components: {
    Loader
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      error: false,
      email: "",
      password: ""
    }
  },
  methods: {
    async signupUser() {
      try {
        await this.$store.dispatch("signup", {
          email: this.email,
          password: this.password,
        });
        this.$router.push("/dashboard");
      } catch (err) {
        console.log(err.message);
      }
    },
    async logInUser() {
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.error = false;
        this.$router.push(`${this.$store.state.url}`);
      } catch (err) {
        this.error = true;
        console.log(err.message);
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
